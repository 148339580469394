import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import Header from "./Components/Layout/Header";

const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./Screens/Home/index")),
  },
  {
    path: "/aboutUs",
    exact: true,
    Component: lazy(() => import("./Screens/AboutUs/index")),
  },
  {
    path: "/retailErp",
    exact: true,
    Component: lazy(() => import("./Screens/RetailErp/index")),
  },
  {
    path: "/clinic",
    exact: true,
    Component: lazy(() => import("./Screens/ClinicManagement/index")),
  },
  {
    path: "/appointment",
    exact: true,
    Component: lazy(() => import("./Screens/Appointment/index")),
  },
  {
    path: "/prajabox",
    exact: true,
    Component: lazy(() => import("./Screens/Voterbox")),
  },
  {
    path: "/services",
    exact: true,
    Component: lazy(() => import("./Screens/Services/index")),
  },
  {
    path: "/contact",
    exact: true,
    Component: lazy(() => import("./Screens/Contact/index")),
  },
  {
    path: "/school",
    exact: true,
    Component: lazy(() => import("./Screens/School/index")),
  },
  {
    path: "/industry-serve",
    exact: true,
    Component: lazy(() => import("./Screens/IndustryServed/index")),
  },
  {
    path: "/coming-soon",
    exact: true,
    Component: lazy(() => import("./Screens/ComingSoon/index")),
  },
  {
    path: "/privacy-policy",
    exact: true,
    Component: lazy(() => import("./Screens/PrivacyPolicy")),
  },
  {
    path: "/terms-conditions",
    exact: true,
    Component: lazy(() => import("./Screens/TermsConditions")),
  },
  {
    path: "/refund-policy",
    exact: true,
    Component: lazy(() => import("./Screens/RefundPolicy")),
  },
];

class App extends React.Component {
  render() {
    return (
      <Fragment>
        <Helmet titleTemplate="Trixno | %s" title="Title" />
        <Header />
        <Route exact path="/home" render={() => <Redirect to="/" />} />
        {routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <Suspense fallback={null}>
                  <Component />
                </Suspense>
              );
            }}
          />
        ))}

        {/* <Footer /> */}
      </Fragment>
    );
  }
}

export default withRouter(App);
