import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/img/trixno.png";
import MyDesktop from "../../MyComponents/MyDesktop";
import MyMobile from "../../MyComponents/MyMobile";

class Header extends Component {
  state = {
    drawerOpen: false,
  };

  render() {
    return (
      <React.Fragment>
        {/* DESKTOP HEADER SECTION */}
        <MyDesktop>
          <div
            className={
              "fixed-top shadow-sm " +
              (window.location.pathname === "/school"
                ? "th-bg-yellow"
                : "bg-white")
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div className="my-auto py-3">
                      <Link to="/">
                        <img
                          alt="Trixno Logo"
                          className="my-auto"
                          src={Logo}
                          style={{
                            height: 24,
                          }}
                        />
                      </Link>
                    </div>
                    <div className="row mt-2">
                      <Link className="mt-3" to="/">
                        <div
                          className={
                            "px-3 th-14 th-black pb-2 " +
                            (window.location.pathname === "/"
                              ? "th-header-underline th-fw-900"
                              : "")
                          }
                        >
                          Home
                        </div>
                      </Link>
                      <Link className="mt-3" to="/services">
                        <div
                          className={
                            "px-3 th-14 th-black pb-2 " +
                            (window.location.pathname === "/services"
                              ? "th-header-underline th-fw-900"
                              : "")
                          }
                        >
                          Services
                        </div>
                      </Link>

                      <div className="th-header-dropdown mt-3">
                        <div
                          className={
                            "th-header-dropbtn px-3 th-14 " +
                            (window.location.pathname === "/school" ||
                            window.location.pathname === "/retailErp"
                              ? "th-header-underline th-fw-900"
                              : "")
                          }
                        >
                          Products
                        </div>
                        <div className="th-header-dropdown-content">
                          <Link to="/school">School ERP</Link>
                          <Link to="/retailErp">Retail ERP</Link>
                          <Link to="/clinic">Clinic Management</Link>
                          <Link to="/appointment">Appointment Management</Link>
                          <Link to="/prajabox">Prajabox</Link>
                        </div>
                      </div>

                      <Link className="mt-3" to="/aboutUs">
                        <div
                          className={
                            "px-3 th-14 th-black pb-2 " +
                            (window.location.pathname === "/aboutUs"
                              ? "th-header-underline th-fw-900"
                              : "")
                          }
                        >
                          About
                        </div>
                      </Link>
                      <Link className="mt-3" to="/industry-serve">
                        <div
                          className={
                            "px-3 th-14 th-black pb-2 " +
                            (window.location.pathname === "/industry-serve"
                              ? "th-header-underline th-fw-900"
                              : "")
                          }
                        >
                          Industry we serve
                        </div>
                      </Link>
                      <Link className="mt-3" to="/contact">
                        <div
                          className={
                            "px-3 th-14 th-black pb-2 " +
                            (window.location.pathname === "/contact"
                              ? "th-header-underline th-fw-900"
                              : "")
                          }
                        >
                          Contact
                        </div>
                      </Link>

                      <div className="mt-3">
                        <a
                          className="th-black th-link th-18"
                          href="https://www.linkedin.com/company/trixno"
                        >
                          <i class="fab fa-linkedin-in px-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: 57.21 }}></div>
        </MyDesktop>

        {/* MONILE HEADER SECTION */}
        <MyMobile>
          <div className="fixed-top bg-white shadow-sm">
            <div className="container py-1">
              <div className="row">
                <div
                  className="col-2 py-2"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  <div className="my-auto">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="col-8 text-center py-2">
                  <div className="my-auto">
                    <Link to="/">
                      <img
                        alt="Trixno Logo"
                        className="my-auto"
                        src={Logo}
                        style={{
                          height: 24,
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>

          <div style={{ height: 49.21 }}></div>

          {/* Drawer */}

          {this.state.drawerOpen ? (
            <div
              className="th-side-drawer-backdrop"
              onClick={() =>
                this.setState({ drawerOpen: !this.state.drawerOpen })
              }
            />
          ) : null}

          <div
            className={
              this.state.drawerOpen
                ? "th-side-drawer open shadow-sm py-5 rounded-right"
                : "th-side-drawer shadow-sm py-5 rounded-right"
            }
          >
            <div className="text-center">
              <div className="my-auto">
                <Link
                  to="/"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  <img
                    alt="Trixno Logo"
                    className="my-auto"
                    src={Logo}
                    style={{
                      height: 24,
                    }}
                  />
                </Link>
              </div>
            </div>
            <hr className="mb-4 px-3" />

            <div className="my-3 px-3">
              <Link
                className={
                  "th-16 w-100 " +
                  (window.location.pathname === "/" ? "th-primary" : "th-link")
                }
                to="/"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Home
              </Link>
            </div>
            <div className="my-3 px-3">
              <Link
                className={
                  "th-16 w-100 " +
                  (window.location.pathname === "/services"
                    ? "th-primary"
                    : "th-link")
                }
                to="/services"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Services
              </Link>
            </div>
            <div className="my-3 px-3">
              <Link
                className={
                  "th-16 w-100 " +
                  (window.location.pathname === "/aboutus"
                    ? "th-primary"
                    : "th-link")
                }
                to="/aboutus"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                About Us
              </Link>
            </div>
            <div className="my-3 px-3">
              <Link
                className={
                  "th-16 w-100 " +
                  (window.location.pathname === "/industry-serve"
                    ? "th-primary"
                    : "th-link")
                }
                to="/industry-serve"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Industry we serve
              </Link>
            </div>
            <div className="my-3 px-3">
              <Link
                className={
                  "th-16 w-100 " +
                  (window.location.pathname === "/contact"
                    ? "th-primary"
                    : "th-link")
                }
                to="/contact"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Contact Us
              </Link>
            </div>

            <div className="bg-light p-3">
              <div className="my-3 th-text-hint th-fw-300">ERP Solutions :</div>
              <div className="my-3 pl-3">
                <Link
                  className={
                    "th-16 w-100 " +
                    (window.location.pathname === "/school"
                      ? "th-primary"
                      : "th-link")
                  }
                  to="/school"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  School ERP
                </Link>
              </div>

              <div className="my-3 pl-3">
                <Link
                  className={
                    "th-16 w-100 " +
                    (window.location.pathname === "/retailErp"
                      ? "th-primary"
                      : "th-link")
                  }
                  to="/retailErp"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  Retail ERP
                </Link>
              </div>
              <div className="my-3 pl-3">
                <Link
                  className={
                    "th-16 w-100 " +
                    (window.location.pathname === "/clinic"
                      ? "th-primary"
                      : "th-link")
                  }
                  to="/clinic"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  Clinic Management
                </Link>
              </div>
              <div className="my-3 pl-3">
                <Link
                  className={
                    "th-16 w-100 " +
                    (window.location.pathname === "/appointment"
                      ? "th-primary"
                      : "th-link")
                  }
                  to="/appointment"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  Appointment Management
                </Link>
              </div>

              <div className="my-3 pl-3">
                <Link
                  className={
                    "th-16 w-100 " +
                    (window.location.pathname === "/prajabox"
                      ? "th-primary"
                      : "th-link")
                  }
                  to="/prajabox"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  Prajabox
                </Link>
              </div>
            </div>

            <p className="th-grey th-12 text-center pt-3">www.trixno.com</p>
          </div>
        </MyMobile>
      </React.Fragment>
    );
  }
}

export default Header;
